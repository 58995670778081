<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">ユーザー管理{{isCollapsed ?"(一覧モード)":"(登録モード)"}}</label>
        <div class="card-header-actions">
          <CButton size="sm" color="success mr-1" v-show="administratorShow" variant="outline" :pressed="toggleA" @click="toggleButton('A',true)"  v-c-tooltip="{content: '該当ユーザー登録する',placement:'bottom' }">アドミニストレータ</CButton>
          <CButton size="sm" color="info mr-1" variant="outline" :pressed="toggleR" @click="toggleButton('R',true)">レジストレータ</CButton>
          <CButton size="sm" color="warning mr-1" variant="outline" :pressed="toggleM" @click="toggleButton('M',true)">メンテナンス</CButton>

          <CButton size="sm" color="primary mr-1 ml-5" variant="outline" :pressed="toggleP" @click="toggleButton('P',true)">プロバイダ</CButton>
          <CButton size="sm" color="dark mr-1" variant="outline" :pressed="toggleH" @click="toggleButton('H',true)">医療関係者</CButton>

          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="200">
      <CCardBody class="m-0 p-0">
        <div>
            <CCard class="notice_form p-0 m-0">
              <CCardBody>
                <CForm>
                  <CRow v-show="toggleA || toggleR || toggleM">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="会社名" v-model="formData.companyName" placeholder="会社名を入力してください" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="部署名" v-model="formData.companyDeptName" placeholder="部署名を入力してください" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="医療機関" v-show="toggleH" v-model="hospitalName"
                              placeholder="選択してください" readonly @click="hospitalSelect" addLabelClasses="required"/>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="姓" v-model="formData.lastName" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="名" v-model="formData.firstName" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="セイ" v-model="formData.lastNameKana" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="メイ" v-model="formData.firstNameKana" addLabelClasses="required"/>
                    </CCol>
                  </CRow>
                  <CRow v-show="toggleP">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="販売店" v-model="agencyName" placeholder="選択してください" readonly
                              @click="showAgencyModal=true" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="事業所" v-model="agencyBranchName" placeholder="選択してください"
                              readonly
                              @click="showAgencyModal=true"  addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow v-show="toggleH">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="登録依頼販売店" v-model="requestAgencyName" placeholder="選択してください"
                              readonly @click="showAgencyBranchModal=true" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="登録依頼事業所" v-model="requestAgencyBranchName"
                              placeholder="選択してください"
                              readonly @click="showAgencyBranchModal=true" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="連絡先電話番号" @keypress="isNumber($event)"
                              v-model="formData.tel"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="携帯電話番号" @keypress="isNumber($event)"
                              v-model="formData.mobile"/>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="連絡先メールアドレス" v-model="formData.email" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <!--                  <CInput class="mt-0" horizontal label="担当プロバイダ" v-show="toggleH" v-model="mappingProviderName" placeholder="選択してください" readonly @click="mappingProviderClick"/>-->
                      <CInput class="mt-0" horizontal label="販売店通知先メールアドレス" v-show="toggleH"
                              v-model="formData.loginStatusContacEmail" placeholder="販売店通知先メールアドレスを入力してください。" addLabelClasses="required"/>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <CCardFooter>
                <SaveButtons :id="formData.id" @formInit="formInit" @save="save" :show-save-btn="showSaveButton"/>
              </CCardFooter>
            </CCard>

        </div>
      </CCardBody>
      </CCollapse>
    </CCard>
    <!--プロバイダの事業所選択用-->
    <CModal :show.sync="showAgencyModal"
            size="xl"
            :centered="true"
            title="販売店&事業所">
      <AgencyWithBranch ref="agencyUser" @filterAgencyUser="filterAgencyUser"
                        @addAgencyUser="addAgencyUser"
                        @setAgencyWithBranch="setAgencyWithBranch" :isShow="showAgencyModal"></AgencyWithBranch>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <!--  医療関係者  -->
    <CModal :show.sync="showHospitalModal"
            size="xl"
            :no-close-on-backdrop="true"
            :centered="true"
            title="医療機関選択">
      <Hospital @setHospital="setHospital" :isShow="showHospitalModal"></Hospital>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <!--医療関係者の登録依頼販売店事業所泉たくよう-->
    <CModal :show.sync="showAgencyBranchModal"
            size="xl"
            :centered="true"
            title="登録依頼販売店&事業所選択">
      <AgencyWithBranch
          @setAgencyWithBranch="setRequestAgencyWithBranch" :isShow="showAgencyBranchModal"></AgencyWithBranch>
      <template #footer-wrapper><span></span></template>
    </CModal>
    <CCard class="m-0 p-0">
      <CCardHeader class="usersHeader text-center ">
<!--        <strong> ユーザー一覧</strong>-->
        <span v-show="filterHospitalId">医療機関ID:{{ filterHospitalId }}</span><span
          v-show="filterAgencyId">販売店ID:{{ filterAgencyId }}</span>
      </CCardHeader>
      <CCardBody class="usersBody">
        <UserList ref="childUserList" @updateUser="updateUser" :isCollapsed="isCollapsed"></UserList>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Hospital from "@/component/Hospital";
import AgencyWithBranch from "@/component/AgencyWithBranch";
import UserList from "@/component/UserList";
import SaveButtons from "@/component/parts/SaveButtons";
import BeeMix from "@/views/aprod/js/mixin/beeUtils";

export default {
  name: 'UserManage',
  components: {
    Hospital, AgencyWithBranch, UserList, SaveButtons
  },
  mixins: [BeeMix],
  data: () => {
    return  {
      columns:[],
      superRegisterModalOpen: false,
      superRegisterModalColor: 'warning',
      selected: "",
      isCollapsed: true,
      orgTypes: [],
      prefectures: [],
      cities: [],
      formData: {
        id: null,
        companyName:null,
        companyDeptName:null,
        lastName: null,
        firstName: null,
        lastNameKana: null,
        firstNameKana: null,
        agencyId: null,
        agencyBranchId: null,
        tel: null,
        mobile: null,
        hospitalId: null,
        role: null, //ADMINISTRATOR,REGISTRATOR,MAINTENANCE,CENTER,PROVIDER,DOCTOR
        //mappingProviderId:null
        loginStatusContacEmail: null,
        requestAgencyBranchId: null,
      },
      toggleA: true,
      toggleR: false,
      toggleM: false,
      toggleC: false,
      toggleP: false,
      toggleH: false,
      agencyName: null,
      agencyBranchName: null,
      requestAgencyName: null,
      requestAgencyBranchName: null,
      showHospital: false,
      showHospitalModal: false,
      showAgency: false,
      showAgencyModal: false,
      // showProviderModal:false,
      filterAgencyId: null,
      filterAgencyBranchId: null,
      filterHospitalId: null,
      hospitalName: null,
      mappingProviderName: null,
      showAgencyBranchModal: false, //
      //addUser:{}
    }
  },
  methods: {
    copyUser: function () {
      this.formData = Object.assign(this.formData, {id: null});
    },

    setAgencyWithBranch: function (row) {
      this.formData.agencyId = row.agencyId;
      this.formData.agencyBranchId = row.agencyBranchId;

      this.agencyName = row.agencyName;
      this.agencyBranchName = row.agencyBranchName;
      this.showAgencyModal = false;
    },
    setRequestAgencyWithBranch: function (row) {
      this.formData.requestAgencyBranchId = row.agencyBranchId;
      this.requestAgencyName = row.agencyName;
      this.requestAgencyBranchName = row.agencyBranchName;
      this.showAgencyBranchModal = false;
    },
    setHospital: function (row) {
      this.formData.hospitalId = row.id;
      this.hospitalName = row.name;
      this.showHospitalModal = false;
    },
    hospitalSelect: function () {
      this.showHospitalModal = true;
    },
    agencyBranchClick: function () {
      this.showAgencyModal = true;
    },
    // mappingProviderClick:function(){
    //   this.showProviderModal=true;
    // },
    // setProvider:function(row){
    //   this.mappingProviderName=`${row.orgName} (${row.agencyBranchName}) ${row.userName}`;
    //   this.formData.mappingProviderId = row.id;
    //   this.showProviderModal = false;
    // },
    filterAgencyUser: function (row) {

      this.filterAgencyId = row.agencyId;
      this.filterAgencyBranchId = row.agencyBranchId;
      this.filterHospitalId = null;
      this.$refs.agencyUser.goodPaging();
    }, addAgencyUser: function (row) {

      this.addUser = {userType: "PROVIDER", createType: "create", orgName: row.agencyName};
      this.formData = {
        role: "PROVIDER",
        agencyBranchId: row.agencyBranchId,
        agencyBranchName: row.agencyBranchName,
        agencyId: row.agencyId
      };
    },
    filterDoctor: function (row) {
      this.filterHospitalId = row.id;
      this.filterAgencyId = null;
      this.goodPaging();
    },
    addDoctor: function (row) {
      // this.addUser = {userType:"PROVIDER",createType:"update",orgName:row.agencyName};
      this.addUser = {userType: "DOCTOR", createType: "create", orgName: row.name};
      this.formData = {
        hospitalId: row.id,
        role: "DOCTOR",
        agencyBranchId: null,
        agencyBranchName: null,
        agencyId: null
      }
    },
    toggleButton: function (type, isInit) {
      this.toggleA = false;
      this.toggleR = false;
      this.toggleM = false;
      this.toggleC = false;
      this.toggleP = false;
      this.toggleH = false;

      this.filterHospitalId = null;
      this.filterAgencyId = null;

      if (isInit) {
        this.formData = {};
      }

      let test = false;
      if (type == 'A') {
        this.toggleA = true;
        this.showAgency = false;
        this.showHospital = false;
      } else if (type == 'R') {
        this.toggleR = true;
        this.showAgency = false;
        this.showHospital = false;

      } else if (type == 'M') {
        this.toggleM = true;
        this.showAgency = false;
        this.showHospital = false;

      } else if (type == 'P') {
        this.toggleP = true;
        this.showAgency = true;
        this.showHospital = false;

      } else {//H
        this.toggleH = true;
        if (test) {
          this.formData = {
            lastName: "倉庫",
            firstName: "者",
            lastNameKana: "セン",
            firstNameKana: "ター",
            agencyBranchId: null,
            agencyDeptId: null,
            tel: "00000000000",
            mobile: "00000000000",
            agencyId: null,
            hospitalId: null,
            role: "DOCTOR", //ADMINISTRATOR,REGISTRATOR,MAINTENANCE,CENTER,PROVIDER,DOCTOR
            email: "hinoko58@gmail.com",
            mappingProviderName: null,
            // mappingProviderId:null
          }
        }
        this.showAgency = false;
        this.showHospital = true;
      }
      this.isCollapsed = false;
    },
    formInit: function () {
      this.formData = {};
      this.hospitalName = null;
      this.agencyName = null;
      this.agencyBranchName = null;
      this.mappingProviderName = null;
      this.requestAgencyName = null;
      this.requestAgencyBranchName = null;

    }, save: function () {
      if (this.toggleA) {
        this.formData.role = "ADMINISTRATOR";
      } else if (this.toggleR) {
        this.formData.role = "REGISTRATOR";
      } else if (this.toggleP) {
        this.formData.role = "PROVIDER";
      } else if (this.toggleM) {
        this.formData.role = "MAINTENANCE";
      } else if (this.toggleH) {
        this.formData.role = "DOCTOR";
      }
      this.axios.post('/api/user/save', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
        if (response.data.result) {
          this.formData.id = response.data.result;
        }
        this.goodPaging();
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);

    },
    goodPaging: function () {
      this.$refs.childUserList.goodPaging();
    },
    updateUser: function (data) {
      this.axios.get(`/api/getUserData/${data.id}`).then(response => {
        // console.log("updateUserupdateUserupdateUserupdateUserupdateUser1",JSON.stringify(response.data.result));
        this.formData = Object.assign({}, response.data.result);
        console.log("updateUserupdateUserupdateUserupdateUserupdateUser2", JSON.stringify(this.formData));
        // this.addUser = {userType:"PROVIDER",createType:"update",orgName:response.data.result.agencyName,agencyBranchName:response.data.result.agencyBranchName};
        this.isCollapsed = false;
        let data = response.data.result;
        let role = data.role;
        if (role == "PROVIDER") {
          this.agencyName = data.orgName;
          this.agencyBranchName = data.agencyBranchName;
          this.formData.agencyId = data.agencyId;
          this.formData.agencyBranchId = data.agencyBranchId;

        } else if (role == "DOCTOR") {
          this.formData.hospitalId = data.hospitalId;
          // this.formData.mappingProviderId = data.mappingProviderId;
          this.requestAgencyName = data.requestAgencyName;
          this.requestAgencyBranchName = data.requestAgencyBranchName;
          this.requestAgencyId = data.requestAgencyId;
          this.requestAgencyBranchId = data.requestAgencyBranchId;
          this.hospitalName = data.orgName;
          //this.mappingProviderName = `${data.mappingProviderAgencyName} ${data.mappingProviderAgencyBranchName} ${data.mappingProviderName}`;
        }
        this.changeUserButton(response.data.result.role);
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    changeUserButton: function (role) {
      let toggleType = role[0];
      this.toggleButton(toggleType, false);
    },
    getOptions: function () {
      this.axios.post('/api/hospital/getOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.orgTypes = response.data.result.orgTypes;
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
  },
  mounted() {
    this.getOptions();
    // this.goodPaging();
    if (this.$store.state.user.role == 'ADMINISTRATOR') {
      this.toggleA = true;
    } else {
      this.toggleR = true;
    }

  },
  watch:{
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  }, computed: {
    showSaveButton(){
      return !(this.formData.role == "ADMINISTRATOR" && this.isRegistrator());

    },
    cityId() {
      return this.formData.cityId;
    }, administratorShow() {
      return this.$store.state.user.role == 'ADMINISTRATOR';
    }
  },
}
</script>
<style scoped>
label {
  color: #3C4B64;
}

.usersHeader, .usersBody {
  padding: 3px 0px;
}

</style>